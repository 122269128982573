import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <img src={"./favicon.ico"} className="" alt="logo" />
        <h1 style={{color:'#96C9F4'}}>
          <strong>COMING SOON</strong>
        </h1>
        
      </header>
    </div>
  );
}

export default App;
